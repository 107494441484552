import React from 'react'

import { Lead, List, ListItem, Typography } from '@te-digi/styleguide'

import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { Code } from '../../components/Code'

const Page = () => (
  <Content heading="Saavutettavuus UX-suunnittelussa">
    <Section>
      <Lead>
        Hyvässä UX-suunnittelussa otetaan huomioon saavutettavuuden periaatteet.
        Tällöin käyttöliittymän komponentit, tekstit, painikkeet, linkit ja koko
        kokonaisuus on suunniteltu saavutettavaksi. Työmarkkinatori noudattaa
        WCAG 2.1 -ohjeistuksen AA-tason kriteerejä. Seuraava
        saavutettavuusasioiden tarkistuslista on tarkoitettu avuksi
        Työmarkkinatorin käyttöliittymien suunnitteluun.
      </Lead>
    </Section>
    <Typography>
      <Section title="UX-suunnittelijan tarkastuslista">
        <SectionHeading>
          Tarkista komponentin saavutettavuusmääritelmät
        </SectionHeading>
        <List variant="unordered">
          <ListItem>
            Style Guide -komponentit tehdään saavutettavuus huomioon ottaen.
            Tarkista mitä käytössäsi olevan komponentin saavutettavuudesta
            kerrotaan ja lisää tarvittaessa huomioita.
          </ListItem>
        </List>
        <SectionHeading>Merkitse otsikoille tasot tarvittaessa</SectionHeading>
        <List variant="unordered">
          <ListItem>
            Hyvä otsikkorakenne auttaa ruudunlukuohjelman käyttäjiä selaamaan
            sivua otsikkojen perusteella. Sivun pääsisällön tulee alkaa
            ensimmäisen tason (<Code>&lt;h1&gt;</Code>) otsikolla. Otsikkotasoja
            ei saa jättää välistä eli jos käyttää otsikkoa{' '}
            <Code>&lt;h1&gt;</Code>, sitä tulee aina seurata{' '}
            <Code>&lt;h2&gt;</Code> eikä <Code>&lt;h3&gt;</Code>.
          </ListItem>
          <ListItem>
            Jos otsikkotasojen ja hierarkian havainnointi käyttöliittymäkuvista
            ei ole suoraviivaista (otsikkokoot eivät mene loogisesti suuremmasta
            pienempään), merkitse otsikoille tasot.
          </ListItem>
          <ListItem>
            Otsikkotasot voivat käyttää tyylillisesti eri otsikkokokoja.
          </ListItem>
        </List>
        <SectionHeading>
          Tarkista linkkien ja painikkeiden tekstit
        </SectionHeading>
        <List variant="unordered">
          <ListItem>
            Näytöllä oleva teksti ja ruudunlukijan teksti voivat tarvittaessa
            erota toisistaan. Ruudunlukuohjelmaa käyttävälle linkin tai
            painikkeen nimestä pitää tulla selväksi, mitä toiminto tekee (vrt.
            Lisää / Lisää työsuhde) tai mihin toiminto vie (esim. linkki
            ulkopuolisiin sivuihin). Kontekstin ymmärtäminen linkkien nimistä on
            tärkeää, koska usein ruudunlukuohjelman käyttäjät selaavat
            verkkosivuja läpi lukemalla vain sivuilla olevat linkit.
          </ListItem>
          <ListItem>
            Yleensä teksti on mahdollista muodostaa yhdistämällä olemassa olevia
            tekstejä. Neuvottele toteutustiimin kanssa, miten teksti olisi
            parasta muodostaa.
          </ListItem>
        </List>
        <SectionHeading>
          Merkitse käyttöliittymäkuviin tarvittaessa etenemisjärjestys
          näppäimistölle
        </SectionHeading>
        <List variant="unordered">
          <ListItem>
            Sivun elementit asettuvat oletuksena mobiilissa näkyvään
            etenemisjärjestykseen.
          </ListItem>
          <ListItem>
            Haluttu etenemisjärjestys voi tarvittaessa erota visuaalisesti
            näkyvästä järjestyksestä.
          </ListItem>
        </List>
        <SectionHeading>Lisää selitetekstit kuville</SectionHeading>
        <List variant="unordered">
          <ListItem>
            Merkitykselliset tai käytön kannalta oleelliset kuvat tarvitsevat
            selitetekstin ruudunlukijaa varten.
          </ListItem>
          <ListItem>
            Ruudunlukija ei pysty lukemaan kuvissa olevia tekstejä, joten pyri
            välttämään tekstiä sisältäviä kuvia. Jos kuva kuitenkin sisältää
            tekstiä, on muistettava lisätä selitetekstiin myös mitä kuvassa
            lukee.
          </ListItem>
        </List>
        <SectionHeading>
          Vältä lomake-elementtejä, joilla ei ole otsikkoa (labelia)
        </SectionHeading>
        <List variant="unordered">
          <ListItem>
            Lomake-elementtien otsikot ovat tärkeitä ruudunlukuohjelmaa
            käyttävän kontekstin ymmärryksen muodostumiseen.
          </ListItem>
          <ListItem>
            Jos lomake-elementillä ei ole jostain syystä näkyvää otsikkoa, tulee
            sille määritellä ruudunlukijaa varten erillinen otsikko
            (aria-label).
          </ListItem>
        </List>
        <SectionHeading>
          Merkitse tarvittaessa fokusoitava elementti
        </SectionHeading>
        <List variant="unordered">
          <ListItem>
            Style Guide -komponentit ilmaisevat, kun niillä on fokus.
          </ListItem>
          <ListItem>
            Kun fokusoitu elementti poistuu esimerkiksi poisto-toimenpiteen
            seurauksena, fokus siirtyy oletuksena sivun alkuun. Merkitse
            suunnitelmiin mihin fokus tulisi siirtyä.
          </ListItem>
        </List>
        <SectionHeading>
          Pidä myös ruudunlukijalle tarkoitetut tekstit lyhyinä ja ytimekkäinä
        </SectionHeading>
        <List variant="unordered">
          <ListItem>
            Ruudunlukijan käyttäjä ei halua kuulla turhaa tekstiä, aivan kuin
            näkevä ei halua lukea turhaa tekstiä.
          </ListItem>
        </List>
      </Section>
    </Typography>
  </Content>
)

export default Page
